/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

:root {
    // TUDO
    // Se for mudar, preencha com os dois tipos de valores, tanto HEX (primeiro), quanto RGB(segundo)
    // --color-main: #ff6d00;
    // --color-main-rgb: 255, 109, 0;
    --color-main: #2e7444;
    --color-main-rgb: 46, 116, 68;
    //
    // HOME
    //
    // Texto de descrição dos cartões de competências (abaixo do banner)
    // Texto de descrição da seção de cotação (abaixo da faixa de 'tem alguma dúvida?')
    // Texto de descrição dos principais produtos
    --home-color-summary: #000000DE;
    // -------------------------------------
    //
    // Cor de todos os botões da página principal
    // --home-color-button: #ff6d00;
    --home-color-button: #2e7444;
    // -------------------------------------
    //
    // Cor do envelopamento redondo dos ícones dos cartões de competências (abaixo do banner)
    // Como é um gradiente, o primeiro é a parte de cima, e o segundo a parte de baixo
    // --home-color-icon-round-start: #ff6d00;
    // --home-color-icon-round-finish: #ff9100;
    --home-color-icon-round-start: #1f5831;
    --home-color-icon-round-finish: #2e7444;
    // -------------------------------------
    //
    // Cor das sombras dos cartões de competências (abaixo do banner)
    // RGBA obrigatório, HEX não irá funcionar corretamente
    // Altere o último valor para deixar a cor mais fraca, ou mais forte (0.0 até 1.0)
    // MOBILE
    --color-home-shadow-mob: rgba(var(--color-main-rgb), 0.2);
    // DESKTOP
    --color-home-shadowtop-desk: rgba(var(--color-main-rgb), 0.2); // Parte superior do cartão
    --color-home-shadowbot-desk: rgba(var(--color-main-rgb), 0.12); // Parte inferior do cartão
    // -------------------------------------
    //
    // Cor sobreposta à faixa de dúvida ('tem alguma dúvida?')
    // RGBA obrigatório, HEX não irá funcionar corretamente
    // Altere o último valor para deixar a cor mais fraca, ou mais forte (0.0 até 1.0)
    --home-color-card-overlay: rgba(var(--color-main-rgb), 0.85);
    // -------------------------------------
    //
    // Imagem de fundo da faixa de dúvida
    // Procure inserir uma imagem de alta qualidade
    // -------------------------------------
}
